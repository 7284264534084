// src/App.tsx

import React from 'react';
import ResponsiveImage from './components/ResponsiveImage';
import './index.css';
import { Analytics } from '@vercel/analytics/react';

const App: React.FC = () => {
  return (
    <div className='App'>
      <ResponsiveImage />
      <Analytics />
    </div>
  );
};

export default App;
