import React, { useEffect, useRef, useState } from 'react';
import { Engine, Render, World, Bodies, Runner, Body, Events } from 'matter-js';
import Matter from 'matter-js';
import '../index.css'; // Import the CSS file

const TELEGRAM_BOT_URL = 'https://t.me/niftynerds_bot';
const X_URL = 'https://x.com/niftynerds';

// iPhone 15 Pro 기준 화면 크기 (CSS 픽셀 기준, 실제 해상도의 약 1/3)
const MOBILE_WIDTH = 390;
const MOBILE_HEIGHT = 844;

const ResponsiveImage: React.FC = () => {
  const scene = useRef<HTMLDivElement>(null);
  const engine = useRef<Engine | null>(null);
  const particles = useRef<Body[]>([]);
  const runner = useRef<Runner | null>(null);
  const isInitialized = useRef(false);
  const particleContainer = useRef<HTMLDivElement | null>(null);
  const clickableImageWrapperRef = useRef<HTMLDivElement | null>(null);
  const [imageSrc, setImageSrc] = useState('assets/LANDINGWEB_BOX_IDLE.gif');
  const [clickCount, setClickCount] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [buttonText, setButtonText] = useState('PLAY NOW');
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handlePlayNowClick = () => {
    //오픈 전 처리
    // setButtonText('SOON...');
    // setIsButtonClicked(true);

    //오픈 후 처리
    window.location.href = TELEGRAM_BOT_URL;
  };

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    // 화면 크기에 따른 속도 조정 계수 계산 (제곱근을 사용하여 증가율 감소)
    const velocityScale = Math.sqrt(
      Math.max(width / MOBILE_WIDTH, height / MOBILE_HEIGHT)
    );

    const images = [
      'assets/LANDINGWEB_LOGO_01.png',
      'assets/LANDINGWEB_LOGO_02.png',
      'assets/LANDINGWEB_LOGO_03.png',
      'assets/LANDINGWEB_LOGO_04.png',
      'assets/LANDINGWEB_LOGO_05.png',
      'assets/LANDINGWEB_LOGO_06.png',
      'assets/LANDINGWEB_LOGO_07.png',
      'assets/LANDINGWEB_TELEGRAM.png',
      'assets/LANDINGWEB_X.png',
    ];

    const preloadImages = (srcs: string[]) => {
      const promises = srcs.map((src) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(img);
          img.onerror = reject;
        });
      });
      return Promise.all(promises);
    };

    const setupMatter = (loadedImages: HTMLImageElement[]) => {
      if (!isInitialized.current) {
        engine.current = Engine.create();
        engine.current.world.gravity.y = 1;
        runner.current = Runner.create();

        const render = Render.create({
          element: scene.current!,
          engine: engine.current,
          options: {
            width,
            height,
            wireframes: false,
            background: 'transparent',
          },
        });

        const ground = Bodies.rectangle(width / 2, height - 5, width, 10, {
          isStatic: true,
        });
        World.add(engine.current.world, ground);

        const createParticle = (x: number, y: number) => {
          const randomIndex =
            Math.random() < 0.135
              ? Math.random() < 0.5
                ? 7
                : 8 // 7 for Telegram, 8 for X
              : Math.floor(Math.random() * 7);
          const randomImage = loadedImages[randomIndex];
          const isTelegram = randomIndex === 7;
          const isX = randomIndex === 8;
          const particleType = isTelegram ? 'telegram' : isX ? 'x' : 'normal';

          let particleSize, scaleSize;
          if (isTelegram) {
            particleSize = 80;
            scaleSize = 0.2;
          } else if (isX) {
            particleSize = 65;
            scaleSize = 0.14;
          } else {
            particleSize = 50;
            scaleSize = 0.1;
          }

          const particle = Bodies.rectangle(x, y, particleSize, particleSize, {
            restitution: 0.8,
            friction: 0.001,
            density: 0.001,
            render: {
              sprite: {
                texture: randomImage.src,
                xScale: scaleSize,
                yScale: scaleSize,
              },
            },
            label: particleType,
          });

          if (isTelegram || isX) {
            const clickText = document.createElement('div');
            clickText.className = 'click-text';
            clickText.textContent = 'Click!';
            particleContainer.current?.appendChild(clickText);

            Events.on(engine.current, 'afterUpdate', () => {
              if (clickText && particle.position) {
                clickText.style.left = `${particle.position.x}px`;
                clickText.style.top = `${
                  particle.position.y - (isTelegram ? 60 : 45)
                }px`; // Adjusted for X particle
              }
            });
          }
          // 화면 크기에 따라 조정된 초기 속도 설정 (2배 증가)
          const baseVelocity = 10; // 기본 속도 (10에서 20으로 증가)
          const baseUpwardForce = 14; // 기본 상승력 (12.5에서 25로 증가)
          const maxVelocity = 25; // 최대 속도 제한 (15에서 30으로 증가)

          const xVelocity =
            (Math.random() - 0.5) * baseVelocity * velocityScale;
          const yVelocity =
            -Math.random() * baseVelocity * velocityScale -
            baseUpwardForce * velocityScale;

          // 속도 제한 적용
          const limitedXVelocity = Math.min(
            Math.max(xVelocity, -maxVelocity),
            maxVelocity
          );
          const limitedYVelocity = Math.max(yVelocity, -maxVelocity);

          Body.setVelocity(particle, {
            x: limitedXVelocity,
            y: limitedYVelocity,
          });

          particles.current.push(particle);
          if (engine.current) {
            World.add(engine.current.world, particle);
          }
        };

        const handleMouseDown = (event: MouseEvent) => {
          setClickCount((prevCount) => prevCount + 1); // 클릭 횟수 증가
          const rect = render.canvas.getBoundingClientRect();
          const scaleX = render.canvas.width / rect.width;
          const scaleY = render.canvas.height / rect.height;

          const x = (event.clientX - rect.left) * scaleX;
          const y = (event.clientY - rect.top) * scaleY;
          createParticle(x, y);
          createFireworkEffect(event.clientX, event.clientY);

          if (clickableImageWrapperRef.current) {
            setImageSrc('assets/LANDINGWEB_BOX_CLICK.gif');
            clickableImageWrapperRef.current.classList.add('shake');
            setTimeout(() => {
              clickableImageWrapperRef.current?.classList.remove('shake');
              setImageSrc('assets/LANDINGWEB_BOX_IDLE.gif');
            }, 500);
          }
        };

        const handleParticleClick = (event: MouseEvent) => {
          const rect = render.canvas.getBoundingClientRect();
          const scaleX = render.canvas.width / rect.width;
          const scaleY = render.canvas.height / rect.height;

          const x = (event.clientX - rect.left) * scaleX;
          const y = (event.clientY - rect.top) * scaleY;

          let specialParticleClicked = false;

          particles.current.forEach((particle) => {
            if (Matter.Bounds.contains(particle.bounds, { x, y })) {
              if (particle.label === 'telegram') {
                handlePlayNowClick();

                //오픈후 처리
                specialParticleClicked = true;
              } else if (particle.label === 'x') {
                window.location.href = X_URL;
                specialParticleClicked = true;
              }
            }
          });

          if (!specialParticleClicked) {
            // 여기에 다른 클릭 이벤트를 추가할 수 있습니다.
          }
        };

        const createFireworkEffect = (x: number, y: number) => {
          const numberOfParticles = 20;
          for (let i = 0; i < numberOfParticles; i++) {
            const particle = document.createElement('div');
            particle.className = 'particle';
            particle.style.left = `${x}px`;
            particle.style.top = `${y}px`;
            particleContainer.current?.appendChild(particle);

            setTimeout(() => {
              particle.remove();
            }, 500);
          }
        };

        const image = document.getElementById('clickable-image');
        image?.addEventListener('mousedown', handleMouseDown);
        render.canvas.addEventListener('mousedown', handleParticleClick);

        if (engine.current && runner.current) {
          Runner.run(runner.current, engine.current);
        }
        Render.run(render);

        Events.on(engine.current, 'afterUpdate', () => {
          particles.current.forEach((particle) => {
            if (particle.position.x < 5) {
              Body.setPosition(particle, { x: 5, y: particle.position.y });
              Body.setVelocity(particle, {
                x: -particle.velocity.x * 0.8,
                y: particle.velocity.y,
              });
            } else if (particle.position.x > width - 5) {
              Body.setPosition(particle, {
                x: width - 5,
                y: particle.position.y,
              });
              Body.setVelocity(particle, {
                x: -particle.velocity.x * 0.8,
                y: particle.velocity.y,
              });
            }
            if (particle.position.y > height - 5) {
              Body.setPosition(particle, {
                x: particle.position.x,
                y: height - 5,
              });
              Body.setVelocity(particle, {
                x: particle.velocity.x,
                y: -particle.velocity.y * 0.8,
              });
            }
            if (particle.position.y < 5) {
              Body.setPosition(particle, { x: particle.position.x, y: 5 });
              Body.setVelocity(particle, {
                x: particle.velocity.x,
                y: -particle.velocity.y * 0.8,
              });
            }
          });
        });

        isInitialized.current = true;
      }
    };

    preloadImages(images)
      .then(setupMatter)
      .catch((error) => {
        console.error('Error loading images:', error);
      });
  }, []);

  useEffect(() => {
    if (clickCount === 5) {
      setShowText(true);
      setTimeout(() => setShowButton(true), 1000);
    }
  }, [clickCount]);

  return (
    <div
      ref={scene}
      className='flex items-center justify-center h-screen bg-black'
    >
      <div ref={clickableImageWrapperRef} className='absolute h-[62.5%]'>
        <img
          id='clickable-image'
          src={imageSrc}
          alt='Responsive GIF'
          className='h-full w-auto'
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div
        ref={particleContainer}
        className='absolute top-0 left-0 w-full h-full pointer-events-none'
      />
      {showText && (
        <div id='reward-text' className='fade-in show'>
          <span className='text-line'>All Games. One Launchpad.</span>
          <span className='text-line'>TONs of Rewards.</span>
        </div>
      )}
      {showButton && (
        <button
          id='play-now-button'
          className={`fade-in show ${isButtonClicked ? 'clicked' : ''}`}
          onClick={handlePlayNowClick}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default ResponsiveImage;
